/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "sp_code",
    label: "服务商",
    type: "select",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请选择"
    },
    options: []
  },
  {
    field: "refuel_card_type",
    label: "账户类型",
    type: "select",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请选择"
    },
    options: []
  },
  {
    field: "company_id",
    label: "划出企业",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入"
    },
    options: []
  },
  {
    field: "driver_name",
    label: "收款司机",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入"
    }
  },
  {
    field: "refuel_card_no",
    label: "划入卡号",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入"
    }
  },
  {
    field: ["begin_trans_time", "end_trans_time"],
    iw: 324,
    label: "划拨时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      showTime: true,
      format: "YYYY/MM/DD"
    }
  }
];
