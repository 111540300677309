<template>
  <div class="page-main-box pt-24 pr-16">
    <!-- 有数据 -->
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div class="table-height" :style="{ height: tableHeight + 'px' }">
        <p-table :dataSource="tableDataSource" height="auto">
          <p-t-column type="seq" width="60" title="序号" />
          <p-t-column field="transfer_no" min-width="240" title="划拨订单ID" />
          <p-t-column
            field="amount"
            min-width="122"
            title="订单金额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.amount / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column field="sp_name" min-width="118" title="服务商" />
          <p-t-column
            field="refuel_card_type_name"
            min-width="146"
            title="账户类型"
          />
          <p-t-column field="company_name" min-width="260" title="划出企业">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 15
                  ? row.company_name?.substr(0, 15) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column field="driver_name" min-width="128" title="收款司机" />
          <p-t-column field="refuel_card_no" min-width="146" title="划入卡号" />
          <p-t-column title="划拨时间" field="transfer_time" min-width="183">
            <template v-slot="{ row }">
              {{
                row.transfer_time &&
                  formatToDateTime(row.transfer_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column field="status_name" min-width="126" title="状态">
            <template v-slot="{ row }">
              <Badge
                v-if="row.status_name === '成功'"
                color="#00BF5F"
                :text="row.status_name"
              ></Badge>
              <Badge
                v-if="row.status_name === '失败'"
                color="#ED4D5C"
                :text="row.status_name"
              ></Badge>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { Badge } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useGasTransferPageApi } from "@/apis/oil";
import {
  useCompanyEnumApi,
  useServiceProvideListEnumApi,
  useGasCardTypeListEnumApi
} from "@/apis/enums";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useSearchForm from "@/hooks/UseSearchForm";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import useDebounce from "@/hooks/useDebounce";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "consumer-orders",
  components: { Badge },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const gasTransferPageApi = useGasTransferPageApi();
    const companyEnumApi = useCompanyEnumApi();
    const serviceProvideListEnumApi = useServiceProvideListEnumApi();
    const gasCardTypeListEnumApi = useGasCardTypeListEnumApi();

    // table高度
    const tableHeight = ref();

    const isLoading = ref(false);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    let schemaList = searchHeaderOptions;
    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);
    handleSearch();

    (async () => {
      let [, sp_code] = await $await(serviceProvideListEnumApi());
      schemaList = useSearch(searchHeaderOptions, sp_code, "sp_code");
      let [, card] = await $await(gasCardTypeListEnumApi());
      schemaList = useSearch(searchHeaderOptions, card, "refuel_card_type");
      enumeration.props.onSearch = handleSearch;
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          company_id: filter.value.company_id
            ? filter.value.company_id
            : route.query.id,
          driver_ids: route.query.driver_id ? [route.query.driver_id] : [],
          refuel_card_no: filter.value.refuel_card_no
            ? filter.value.refuel_card_no
            : route.query.card_no,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(gasTransferPageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 充值操作
    const handleRecharge = row => {
      router.push({
        path: "/financial/recharge",
        query: { row: JSON.stringify(row) }
      });
    };
    // 打开服务配置弹窗
    const handleToDetail = row => {
      router.push({
        path: "/financial/account-detail",
        query: {
          id: row.id,
          company_name: row.company_name,
          account_type_desc: row.account_type_desc,
          account_service_desc: row.account_service_desc
        }
      });
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      handleRecharge,
      handleToDetail,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions,
      formatToDateTime,
      isLoading
    };
  }
};
</script>

<style lang="less" scoped></style>
